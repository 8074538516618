@import './_theme.scss';
@import './_common.scss';

.calendar {
  @include size(100%, 800px);
  margin-top: 30px;

  .header {
    @include size(100%, 7%);
    @include flex-row(space-between, baseline);

    .col.col-first {
      @include size(80%, 100%);
      @include flex-column(center, flex-start);
      margin-left: 1%;

      .text {
        font-size: 0.8em;
      }

      .text.month {
        margin-right: 5px;
        font-size: 1.6em;
        font-weight: 600;
      }
    }

    .col.col-end {
      @include size(20%, 100%);
      @include flex-row(flex-end, baseline);

      svg {
        @include size(11%, fit-content);
        margin-left: 5%;
        color: transparentize(gray, 0.2);

        &:hover {
          @include hover-event();
          transform: scale(1.15);
          color: $dark-gray-color;
        }
      }
    }
  }

  .days {
    @include size(100%, fit-content);
    @include flex-row(space-between, center);
    font-weight: 600;
    font-size: 1.65em;
    padding: 2px;
    color: $dark-gray-color;

    .col {
      @include size(12.9%, 100%);
      @include flex-column(flex-end, flex-start);
      padding-left: 1%;
      background: transparentize($step-color, 0.6);
      border-radius: 5px;
    }
  }

  .body {
    @include size(100%, 89%);
    @include flex-column();

    .row {
      @include size(100%, 100%);
      @include flex-row(space-between, center);

      .col {
        @include size(13.5%, 93%);
        @include flex-row(flex-start, flex-start);

        border: 0.4px solid transparentize(gray, 0.4);
        border-radius: 3px;
        font-size: 0.8em;

        span {
          margin: 4px 0 0 4px;
        }

        .not-valid {
          color: $gray-color;
        }

        img {
          opacity: 0.1;
        }

        .time-display {
          display: flex;
          width: 100%;
          justify-content: end;
          padding: 0.5rem;
        }

        .not-valid-time-display {
          display: flex;
          width: 100%;
          justify-content: end;
          padding: 0.5rem;
          color: $gray-color;
        }
      }

      .col.cell.valid {
        &:hover {
          @include hover-event();
          @include shadow(1.5px, $dark-gray-color, 0.1);
          transform: scale(1.01);
          border: none;
          background: transparentize($gray-color, 0.5);
          color: #ffffff;
        }
      }

      .col.cell.selected {
        @include shadow(1.5px, $main-color, 0.1);
        transform: scale(1.02);
        border: none;
        background: $sub-color;
        color: $main-color;
        font-weight: 600;
      }
    }
  }
}